.overlay-modal-panel {
	.overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 110;
		background: rgba(#000, 0.3);
	}
	.modal-panel,
	.modal-panel-full {
		position: fixed;
		opacity: 1;
		transition-property: opacity, transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-in-out;
		z-index: 111;
	}
	.modal-panel {
		width: calc(100% - 3rem);
	}
	.modal-panel-full {
		width: 100%;
	}

	&.collapsed {
		.modal-panel,
		.modal-panel-full {
			opacity: 0;
			transition-property: opacity, transform, z-index;
			transition-duration: 0.3s;
			transition-timing-function: ease-in-out;
			z-index: -10;
		}
	}
}

@media screen and (max-width: 350px) {
	.overlay-modal-panel {
		.modal-panel {
			left: 0;
			width: 100%;
		}
	}
}

@media screen and (max-width: 639px) {
	.overlay-modal-panel {
		.modal-panel,
		.modal-panel-full {
			top: 50%;
			transform: translateY(-50%) scale(1);
		}
		.modal-panel {
			left: 1.5rem;
		}
		.modal-panel-full {
			left: 0;
		}
		&.collapsed {
			.modal-panel,
			.modal-panel-full {
				transform: translateY(-50%) scale(0.95);
			}
		}
	}
}

@media screen and (min-width: 640px) {
	.overlay-modal-panel {
		.modal-panel,
		.modal-panel-full {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
		}
		.modal-panel-full {
			width: calc(100% - 3rem);
		}
		&.collapsed {
			.modal-panel,
			.modal-panel-full {
				transform: translate(-50%, -50%) scale(0.95);
			}
		}
	}
}
