<!-- todo: add a check to ensure that this only fires when there is an error -->
<div
	[ngClass]="{
		'focus-within:ring-2 border-red-500 focus-within:ring-red-600 focus-within:border-red-600':
			formSubmitted && control.errors,
		'border-gray-300 focus-within:ring-gray-400': !formSubmitted && !control.errors,
	}"
	[class]="customStyles"
	class="relative col-span-1 px-3 py-2 border rounded-md shadow-sm input-focus-within"
>
	<label
		[attr.for]="controlName"
		class="absolute top-0 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2"
		[ngClass]="{ 'show-label': value, label: !value }"
	>
		{{ labelText }}
	</label>

	<input
		#input
		[value]="value"
		[attr.id]="controlName"
		[attr.type]="inputType ? inputType : dynamicType"
		[attr.name]="controlName"
		[attr.placeholder]="placeholder"
		[attr.inputmode]="inputMode"
		(input)="updateValue(input.value)"
		[attr.autocomplete]="autocomplete"
		class="block w-full p-0 text-gray-900 border-0 outline-none focus:outline-none focus:ring-0"
	/>
</div>

@if (formSubmitted) {
	<div class="w-full">
		<!-- for required errors -->
		<uc-form-error
			[fieldError]="'Please enter your ' + formFieldName() + '.'"
			[formError]="formSubmitted && control.hasError('required')"
		>
		</uc-form-error>
		<!-- invalid email -->
		<uc-form-error
			fieldError="Please enter a valid email in the format: example@example.com."
			[formError]="
				formSubmitted &&
				control.hasError('emailInvalid') &&
				!control.hasError('required')
			"
		>
		</uc-form-error>
		<!-- email taken -->
		<uc-form-error
			fieldError="This email address is already being used."
			[formError]="
				formSubmitted &&
				control.hasError('emailTaken') &&
				!control.hasError('required')
			"
		>
		</uc-form-error>
		<!-- invalid phone -->
		<uc-form-error
			fieldError="Invalid phone number. Please enter a valid phone number between 4 and 15 digits."
			[formError]="
				formSubmitted &&
				control.hasError('phoneInvalid') &&
				!control.hasError('required')
			"
		>
		</uc-form-error>
	</div>
}
